import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButtonProps } from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#f7f7f7',
  },

  /* Styles applied to the action element. */
  action: {
    flex: '0 0 auto',
    alignSelf: 'center',
  },

  /* Styles applied to the content wrapper element. */
  content: {
    flex: '1 1 auto',
  },
}));

export interface Properties {
  /**
   * The action to display in the dialog header.
   */
  action?: ReactNode;
  /**
   * The content of the Dialog Title.
   */
  title: ReactNode;

  onClose: IconButtonProps['onClick'];
}

const DialogHeader: FC<Properties> = ({ action, title, onClose, children: _children, ...props }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <DialogTitle className={classes.content} {...props}>
        {title}
      </DialogTitle>
      <div className={classes.action}>
        {action}
        <IconButton id="closeDialog" onClick={onClose} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default DialogHeader;
